import React from "react";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "./icons";
import { graphql, useStaticQuery } from "gatsby";

export const Footer = () => {
  const { sanitySiteGlobal } = useStaticQuery(
    graphql`
      query {
        sanitySiteGlobal {
          content {
            social {
              facebook
              instagram
              twitter
              email
            }
          }
        }
      }
    `
  );

  return (
    <footer className="footer">
      <a
        className="footer__contact-us"
        href={`mailto:${sanitySiteGlobal.content.social.email}`}
      >
        Contact Us
      </a>
      <div className="footer__logo">
        <span>&copy;</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="99"
          height="25"
          fill="none"
        >
          <path
            fill="#fff"
            d="M56.703 11.851c-.953 3.985-1.716 6.627-2.292 9.38l-4.31-18.568h8.658a297.736 297.736 0 01-2.056 9.188zm-18.265 0c-.953 3.985-1.717 6.627-2.292 9.38L31.835 2.663h8.658a303.092 303.092 0 01-2.055 9.188zM16.957 16.79l2.532-13.616 2.56 13.616h-5.092zM4.475.937a4.212 4.212 0 00-2.817 1.061l-.031.029c-1.047.952-1.57 2.408-1.57 4 0 .84.221 1.641.665 2.405.443.764 1.45 2.022 2.31 2.87 1.435 1.418 6.672 5.163 6.672 8.387 0 1.123-.302 1.99-.905 2.603-.603.613-1.457.92-2.56.92-.689 0-1.303-.141-1.846-.424a3.488 3.488 0 01-1.309-1.167 6.754 6.754 0 01-.785-1.606 9.012 9.012 0 01-.403-1.882L0 18.686c.132.858.35 1.655.658 2.39a7.715 7.715 0 001.188 1.98 5.179 5.179 0 001.79 1.38c.707.335 1.494.502 2.362.502 1.698 0 3.082-.471 4.152-1.415 1.07-.942 1.671-2.675 1.606-4.018-.195-4.002-4.692-6.944-7.625-9.884a8.793 8.793 0 01-1.478-1.98c-.345-.624-.517-1.255-.517-1.896 0-1.066.318-2.054.955-2.6a1.997 1.997 0 011.301-.482h13.369l-4.468 21.892h2.164l1.245-6.337h5.616l1.217 6.337h2.178l-4.47-21.892h7.56v21.892h1.91V5.819l4.5 18.738h1.866l4.499-18.752v18.752h1.91V2.663h3.582v21.892h1.91V5.819l4.499 18.738h1.867l4.498-18.753v18.752h1.91V2.663h3.932v21.892h2.15V2.663h3.952v21.892h8.955V22.83h-6.805V2.663h9.481v21.892h2.15V2.663H92v21.892h2.152V2.663h4.78V.938H4.475z"
          ></path>
        </svg>
        <span>2021</span>
      </div>
      <div className="footer__social">
        <a href={sanitySiteGlobal.content.social.facebook}>
          <FacebookIcon />
        </a>
        <a href={sanitySiteGlobal.content.social.twitter}>
          <TwitterIcon />
        </a>
        <a href={sanitySiteGlobal.content.social.instagram}>
          <InstagramIcon />
        </a>
      </div>
    </footer>
  );
};

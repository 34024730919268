import React from "react";

export const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
    <path
      fill="#fff"
      d="M16.152 21.175v-6.966h2.333l.349-2.708h-2.682V9.773c0-.784.218-1.32 1.342-1.32h1.434v-2.42a19.193 19.193 0 00-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58V11.5h-2.338v2.708h2.338v6.966H4.75a1 1 0 01-1-1v-16a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1h-4.598z"
    ></path>
  </svg>
);

export const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
    <path
      fill="#fff"
      d="M22.912 5.83a8.385 8.385 0 01-2.402.659 4.196 4.196 0 001.84-2.314c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 00-7.126 3.814 11.874 11.874 0 01-8.62-4.37 4.168 4.168 0 00-.566 2.103c0 1.45.738 2.73 1.86 3.48a4.168 4.168 0 01-1.894-.522v.052a4.185 4.185 0 003.355 4.1 4.208 4.208 0 01-1.89.073 4.185 4.185 0 003.907 2.905 8.393 8.393 0 01-6.191 1.732 11.829 11.829 0 006.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.497 8.497 0 002.087-2.165V5.83z"
    ></path>
  </svg>
);

export const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
    <path
      fill="#fff"
      d="M12.75 2.175c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153.509.5.902 1.104 1.153 1.772.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 01-1.153 1.772c-.5.508-1.105.902-1.772 1.153-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 01-1.772-1.153 4.904 4.904 0 01-1.153-1.772c-.248-.637-.415-1.363-.465-2.428-.047-1.066-.06-1.405-.06-4.122 0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 011.153-1.772A4.897 4.897 0 016.2 2.7c.638-.248 1.362-.415 2.428-.465 1.066-.047 1.405-.06 4.122-.06zm0 5a5 5 0 100 10 5 5 0 000-10zm6.5-.25a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm-6.5 2.25a3 3 0 110 6 3 3 0 010-6z"
    ></path>
  </svg>
);

import React, { useEffect } from "react";
import Helmet from "react-helmet";
import tighpo from "tighpo";
import SimpleReactLightbox from "simple-react-lightbox";

import { Header } from "src/components/header";
import { Footer } from "src/components/footer";
import { SwitchTransition, Transition } from "react-transition-group";
import { MenuContextProvider } from "../context/menu";

import Analytics from "src/components/analytics";

const TRANSITION_DURATION = 400;
const TRANSITION_STYLES = {
  default: {
    transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`
  },
  entering: {
    opacity: 0
  },
  entered: {
    opacity: 1
  },
  exiting: {
    opacity: 0
  },
  exited: {
    opacity: 0
  }
};
import "normalize.css/normalize.css";

import "src/styles/main.scss";
import { Menu } from "src/components/menu";

const Layout = ({
  children,
  siteMetadata,
  location,
  pageContext
}: {
  children: any;
}) => {
  return (
    <MenuContextProvider>
      <SimpleReactLightbox>
        <Helmet title="sammilit"></Helmet>
        <Analytics googleAnalyticsPropertyId={process.env.GATSBY_GA_ID} />
        <div>
          <Header isCartPage={location.pathname.includes("cart")} />

          {!/account/.test(location.pathname) ? (
            <SwitchTransition>
              <Transition
                key={location.pathname}
                mountOnEnter={true}
                unmountOnExit={true}
                appear={true}
                timeout={TRANSITION_DURATION}
              >
                {status => (
                  <main
                    className="site"
                    id="maincontent"
                    style={{
                      ...TRANSITION_STYLES.default,
                      ...TRANSITION_STYLES[status]
                    }}
                  >
                    {children}
                    <Footer />
                  </main>
                )}
              </Transition>
            </SwitchTransition>
          ) : (
            <div>
              {children}
              <Footer />
            </div>
          )}
        </div>
        <Menu></Menu>
      </SimpleReactLightbox>
    </MenuContextProvider>
  );
};

export default Layout;

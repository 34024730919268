import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState
} from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { InclusiveJournalLogo, MenuDotsOpen } from "./svgs";
import { debounce } from "lodash";
import { MenuContext } from "src/context/menu";
import { useCartItems } from "src/context/siteContext";

const HERO_HEIGHT_DESKTOP = 450;
const HERO_HEIGHT_MOBILE = 325;

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path d="M7 5.586l4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.414 1.414L7 8.414l-4.95 4.95L.636 11.95 5.586 7 .636 2.05 2.05.636 7 5.586z"></path>
    </svg>
  );
};

const BackButton = ({
  isCartPage,
  isMenuOpen,
  setMenuOpen
}: {
  isCartPage: boolean;
  isMenuOpen: boolean;
  setMenuOpen: (cb: any) => void;
}) => {
  if (isCartPage) {
    return (
      <Link className="header__back-button" to="/">
        ◂
      </Link>
    );
  }

  return (
    <button
      className={`header__menu-button ${
        isMenuOpen ? "header__menu-button--close" : ""
      }`}
      onClick={() => setMenuOpen((x: boolean) => !x)}
    >
      {isMenuOpen ? <CloseIcon /> : <MenuDotsOpen />}
    </button>
  );
};

const CartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <path
      fill="currentColor"
      d="M9.25 23.825a1.5 1.5 0 11-.001 3.002 1.5 1.5 0 01.001-3.002zm13 0a1.5 1.5 0 11-.001 3.002 1.5 1.5 0 01.001-3.002zM5.922 5.582L9.749 9.41v11.415H23.75v2h-15a1 1 0 01-1-1V10.238L4.506 6.997l1.415-1.415h.001zM19.75 6.825a1 1 0 011 1v2h2.993c.557 0 1.007.456 1.007.995v8.01a1 1 0 01-1.007.995H11.757a1.007 1.007 0 01-1.007-.995v-8.01a1 1 0 011.007-.995h2.992l.001-2a1 1 0 011-1h4zm-6 5h-1v6h1v-6zm6 0h-4v6h4v-6zm3 0h-1v6h1v-6zm-4-3h-2v1h2v-1z"
    ></path>
  </svg>
);

const BuyCartButton = ({
  goToAnchor,
  isBelowHero
}: {
  goToAnchor: (e: any) => void;
  isBelowHero: boolean;
}) => {
  const cartItems = useCartItems();

  const data = useStaticQuery(graphql`
    query {
      siteNav: sanitySiteNav {
        content {
          buyButtonText
          buyButtonLink
          cartButtonText
          cartButtonLink
        }
      }
    }
  `);

  return ((cartItems as unknown) as Array<any>).length >= 1 ? (
    <Link
      to={data.siteNav.content.cartButtonLink}
      onClick={goToAnchor}
      className={`header__buy-button btn btn--icon ${
        isBelowHero ? "btn--primary-white" : "btn--secondary-white"
      }`}
      // className="header__buy-button primary-button-container p2-barlow-strong right-16px header__buy-button--cart"
    >
      {data.siteNav.content.cartButtonText} <CartIcon />
    </Link>
  ) : (
    <a
      href={data.siteNav.content.buyButtonLink}
      onClick={goToAnchor}
      className={`header__buy-button btn ${
        isBelowHero ? "btn--primary-white" : "btn--secondary-white"
      }`}
      // className="header__buy-button primary-button-container p2-barlow-strong right-16px"
    >
      {data.siteNav.content.buyButtonText}
    </a>
  );
};

export const Header = ({ isCartPage }: { isCartPage: boolean }) => {
  const [belowHero, setBelowHero] = useState(false);

  const debouncedScrollHandler = debounce(
    () =>
      setBelowHero(
        window.innerWidth < 768
          ? window.scrollY > HERO_HEIGHT_MOBILE
          : window.scrollY > HERO_HEIGHT_DESKTOP
      ),
    10
  );

  const scrollHandler = useCallback(debouncedScrollHandler, [
    debouncedScrollHandler
  ]);

  // Figure out if we're "passed the hero"
  useLayoutEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [scrollHandler]);

  const { isMenuOpen, setMenuOpen } = useContext(MenuContext);

  const goToAnchor = useCallback(
    e => {
      const id = e.target.getAttribute("href");
      let el;
      try {
        el = document.querySelector(id) as HTMLElement;
      } catch (e) {
        return;
      }

      if (!el) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      el.scrollIntoView({ behavior: "smooth" });
      setMenuOpen(false);

      return false;
    },
    [setMenuOpen]
  );

  return (
    <div
      className={`header ${
        isCartPage || (belowHero && !isMenuOpen) ? "header--white" : ""
      } ${isMenuOpen ? "header--menu-open" : ""}`}
    >
      <BackButton
        isCartPage={isCartPage}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Link className="header__logo-link" to="/">
        <InclusiveJournalLogo className="header__logo" />
      </Link>
      {!isCartPage && (
        <BuyCartButton goToAnchor={goToAnchor} isBelowHero={belowHero} />
      )}
    </div>
  );
};

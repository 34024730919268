import React, { useCallback, useContext, useLayoutEffect } from "react";
import { MenuContext } from "src/context/menu";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "./icons";
import { graphql, Link, useStaticQuery } from "gatsby";

const CartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <path
      fill="currentColor"
      d="M9.25 23.825a1.5 1.5 0 11-.001 3.002 1.5 1.5 0 01.001-3.002zm13 0a1.5 1.5 0 11-.001 3.002 1.5 1.5 0 01.001-3.002zM5.922 5.582L9.749 9.41v11.415H23.75v2h-15a1 1 0 01-1-1V10.238L4.506 6.997l1.415-1.415h.001zM19.75 6.825a1 1 0 011 1v2h2.993c.557 0 1.007.456 1.007.995v8.01a1 1 0 01-1.007.995H11.757a1.007 1.007 0 01-1.007-.995v-8.01a1 1 0 011.007-.995h2.992l.001-2a1 1 0 011-1h4zm-6 5h-1v6h1v-6zm6 0h-4v6h4v-6zm3 0h-1v6h1v-6zm-4-3h-2v1h2v-1z"
    ></path>
  </svg>
);

interface MenuItem {
  link: string;
  title: string;
}

export const Menu = () => {
  const { isMenuOpen, setMenuOpen } = useContext(MenuContext);

  const data = useStaticQuery(graphql`
    query {
      siteNav: sanitySiteNav {
        content {
          cartButtonText
          cartButtonLink
          greaterNavMenuContents {
            link
            title
          }
          lesserNavMenuContents {
            link
            title
          }
        }
      }
      siteGlobal: sanitySiteGlobal {
        content {
          social {
            facebook
            instagram
            twitter
            email
          }
        }
      }
    }
  `);

  useLayoutEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const goToAnchor = useCallback(
    e => {
      const id = e.target.getAttribute("href");
      let el;
      try {
        el = document.querySelector(id) as HTMLElement;
      } catch (e) {
        // Do nothing
      }

      if (!el) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      el.scrollIntoView({ behavior: "smooth" });
      setMenuOpen(false);

      return false;
    },
    [setMenuOpen]
  );

  return (
    <>
      {
        <div className={`site-menu ${isMenuOpen ? "site-menu--open" : ""}`}>
          <div className="site-menu__container">
            <div className="site-menu__left">
              <div className="site-menu__main-links">
                {data.siteNav.content.greaterNavMenuContents.map(
                  (menuItem: MenuItem) => (
                    <a
                      key={menuItem.link}
                      className="site-menu__animated-link h6-spectral-regular"
                      href={menuItem.link}
                      onClick={goToAnchor}
                    >
                      {menuItem.title}
                    </a>
                  )
                )}
              </div>
              <div className="site-menu__secondary-links">
                {data.siteNav.content.lesserNavMenuContents.map(
                  (menuItem: MenuItem) => (
                    <a
                      key={menuItem.link}
                      className="site-menu__animated-link h8-spectral-regular"
                      href={menuItem.link}
                      onClick={goToAnchor}
                    >
                      {menuItem.title}
                    </a>
                  )
                )}
              </div>
              <div className="site-menu__footer">
                <Link
                  to={data.siteNav.content.cartButtonLink}
                  onClick={() => setMenuOpen(false)}
                  className="btn btn--primary-white btn--icon"
                >
                  {data.siteNav.content.cartButtonText} <CartIcon />
                </Link>
                <div className="site-menu__social-media-icons">
                  <a href={data.siteGlobal.content.social.facebook}>
                    <FacebookIcon />
                  </a>
                  <a href={data.siteGlobal.content.social.twitter}>
                    <TwitterIcon />
                  </a>
                  <a href={data.siteGlobal.content.social.instagram}>
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState
} from "react";

export const MenuContext = createContext<{
  isMenuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}>({
  isMenuOpen: false,
  setMenuOpen: () => {}
});

export const MenuContextProvider = ({ children }: { children: ReactNode }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <MenuContext.Provider value={{ isMenuOpen, setMenuOpen }}>
      {children}
    </MenuContext.Provider>
  );
};
